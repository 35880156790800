"use client";

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "../home.module.scss";
import Link from "next/link";
import { AwsFile } from "@/common/entities/files/AwsFiles";
import { ImageWrapper } from "@/common/components/Images/ImageWrapper";

interface DescriptionSectionProps {
    longDescription: string;
    aboutUsUrl?: string;
    aboutUsImage?: AwsFile;
}
const DescriptionSection: React.FC<DescriptionSectionProps> = ({ longDescription, aboutUsUrl, aboutUsImage }) => {
    return (
        <div className={styles.homeSection}>
            <Container className="py-5">
                <Row>
                    <Col md={aboutUsImage ? 6 : 12}>
                        <h3 className={styles.descriptionTitle}>Despre noi</h3>
                        <p className={styles.descriptionText}>{longDescription}</p>

                        {aboutUsUrl && (
                            <div className={styles.descriptionText}>
                                <Link href={aboutUsUrl} title="Despre noi" className="btn btn-primary px-4 py-2">
                                    Despre noi
                                </Link>
                            </div>
                        )}
                    </Col>

                    {aboutUsImage && (
                        <Col>
                            <ImageWrapper className="mt-3 mt-md-0" url={aboutUsImage?.originalFileName} title={"Despre noi"} width={500} height={300} />
                        </Col>
                    )}
                </Row>
            </Container>
        </div>
    );
};

export default DescriptionSection;
