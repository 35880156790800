"use client";

import React from "react";
import SwipperCarousel from "@/components/SwipperCarousel/SwipperCarousel";
import { Container } from "react-bootstrap";
import styled from "styled-components";

export interface Testimonal {
    author: string;
    description: string;
    youtubeLink: string;
}

interface TestimonialSectionProps {
    testimonials: Testimonal[];
}

const Title = styled.h4`
    color: #000;
    margin: 0 auto 2rem auto;
    text-align: center;
    display: block;
    font-weight: bold;
    line-height: 1.7rem;
    font-size: 1.1rem;

    @media (min-width: 992px) {
        font-size: 1.7rem;
        line-height: 2.5rem;
        margin: 1rem auto 3rem auto;
        max-width: 60%;
    }
`;

const SectionTestimonials = styled.div`
    background: #ececec;
    padding: 2rem 0 3rem 0;
    margin: 0;
`;

const TestimonalPerson = styled.div`
    color: #000;
    text-align: center;
`;

const TestimonalUrl = styled.a`
    color: var(--primary-color);
    text-align: center;
    display: block;
`;

const TestimonalText = styled.div`
    color: #000;
    text-align: center;
    padding: 1rem 0.5rem;
    line-height: 1.7rem;
    font-size: 0.85rem;

    @media (min-width: 992px) {
        padding: 2rem 6rem;
    }
`;

const TestimonailSection: React.FC<TestimonialSectionProps> = ({ testimonials }) => {
    const testimonialCard = testimonials.map((testimonial: Testimonal, index: number) => (
        <div key={index}>
            <TestimonalPerson>{testimonial.author}</TestimonalPerson>
            <TestimonalText>{testimonial.description}</TestimonalText>
            {testimonial.youtubeLink && (
                <TestimonalUrl title={testimonial.author} target="_blank" href={testimonial.youtubeLink}>
                    vezi video
                </TestimonalUrl>
            )}
        </div>
    ));

    const testimonialOptions = { clickable: true, dynamicBullets: true };

    return (
        <SectionTestimonials>
            <Container>
                <Title>Evaluările clienţilor</Title>
                <SwipperCarousel
                    pagination={testimonialOptions}
                    navigation={true}
                    spaceBetween={10}
                    loop={false}
                    slidesPerView={2}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        1024: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                    }}
                    elements={testimonialCard}
                />
            </Container>
        </SectionTestimonials>
    );
};

export default TestimonailSection;
