"use client";

import React from "react";
import SwipperCarousel from "@/components/SwipperCarousel/SwipperCarousel";
import ImageNext from "next/image";
import style from "../home.module.scss";
import { Brand } from "@/common/entities/product/Brand";
import { FaEyeSlash } from "react-icons/fa";
import Link from "next/link";

interface BrandsProps {
    brands: Brand[];
    isMobile: boolean;
}

const LogoCarouselSection = ({ brands, isMobile }: BrandsProps) => {
    const awsFilesUrl = process.env.NEXT_PUBLIC_AWS_FILES_URL || "";

    const ImageCard = brands.map((brand, index) => {
        const imageUrl = awsFilesUrl + brand.image?.originalFileName;

        return brand.image?.originalFileName ? (
            <>
                <Link href={brand?.seoDetails?.url ?? ""} title={"Produse"}>
                    <ImageNext
                        loading={"lazy"}
                        width={isMobile ? 110 : 220}
                        height={isMobile ? 30 : 60}
                        key={index}
                        src={imageUrl}
                        alt={brand.name}
                        unoptimized
                    />
                </Link>
            </>
        ) : (
            <FaEyeSlash className="text-white" key={index} size={60} />
        );
    });

    const breakpoints = {
        0: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        640: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 5,
            spaceBetween: 70,
        },
    };

    return (
        <div className={style.carouselWrapper}>
            <SwipperCarousel
                autoplay={{ delay: 3000, disableOnInteraction: true }}
                className="p-1"
                breakpoints={breakpoints}
                spaceBetween={10}
                elements={ImageCard}
            />
        </div>
    );
};

export default LogoCarouselSection;
