"use client";

import React, { useEffect, useState } from "react";
import { Products } from "@/common/entities/product/Product";
import { getProducts } from "@/services/ProductsService";
import style from "@/components/Homepage/home.module.scss";
import ProductCarousel from "@/components/ProductCarousel/ProductCarousel";

const NewProducts = () => {
    const [products, setProducts] = useState<Products[] | undefined>(undefined);

    useEffect(() => {
        getProducts({ from: 0, size: 15, orderDirection: "DESC", orderBy: "products.createdAt" })
            .then((data) => setProducts(data?.data))
            .catch(() => setProducts(undefined));
    }, []);

    if (!products?.length) {
        return <></>;
    }

    return (
        <div className={style.greyFullSections}>
            <div className="container">
                <ProductCarousel title="Produse noi" products={products} />
            </div>
        </div>
    );
};

export default NewProducts;
