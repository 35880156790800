"use client";

import Card from "react-bootstrap/Card";
import style from "../home.module.scss";
import { Button, Col, Container, Row } from "react-bootstrap";
import PlaceHolder from "../../../images/fireplace.jpg";
import Image from "next/image";
import { AwsFile } from "@/common/entities/files/AwsFiles";
import { awsS3BaseURL } from "@/lib/Constants";
import Link from "next/link";

interface CategoryDescriptionProp {
    title: string;
    description: string;
    url: string;
    medianImage?: AwsFile;
}
function CategoryDescription({ title, description, url, medianImage }: CategoryDescriptionProp) {
    return (
        <div className={style.homeSection}>
            <Container className={style.categoryDescription}>
                <Row className={style.categoryDescriptionRow}>
                    <Col md={12} lg={7} className={style.categoryDescriptionCol}>
                        <Image
                            width={700}
                            height={500}
                            className="p-0 img-fluid"
                            src={medianImage ? awsS3BaseURL + medianImage?.originalFileName : PlaceHolder}
                            alt={title}
                            loading={"lazy"}
                        />
                    </Col>

                    <Col md={12} lg={5} className={style.categoryDescriptionCol}>
                        <Card className={style.categoryDescriptionText}>
                            <Card.Body>
                                <Card.Title className={style.cardTitle}>{title}</Card.Title>
                                <div className="mt-3">
                                    <p>{description}</p>

                                    {url && (
                                        <Link href={url}>
                                            <Button variant="primary">Descopera</Button>
                                        </Link>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default CategoryDescription;
